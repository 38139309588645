import CURRENT_USER_QUERY from '@/graphql/queries/CurrentUser';

export default {
  async getCouponLatestReferral({ dispatch }) {
    const {
      data: {
        currentUser: { user },
      },
    } = await this.app.apolloProvider.defaultClient.query({
      fetchPolicy: 'no-cache',
      query: CURRENT_USER_QUERY,
    });
    dispatch('setCouponLatestReferral', user.latest_referral_coupon);
  },
  setCouponLatestReferral({ commit, dispatch }, coupon) {
    dispatch('user/cart/setDiscountCode', coupon, { root: true });
    commit('setCouponLatestReferral', coupon);
  },
};
