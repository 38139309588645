export default {
  SET_SUBSCRIPTION_AUTO_RENEWAL(state, payload) {
    state.subscriptionAutoRenewal = payload;
  },
  SET_DISCOUNT_CODE(state, payload) {
    state.discountCode = payload === '' ? null : payload;
  },
  SET_PRODUCT_REMOVAL(state, payload) {
    state.productRemoval = payload;
  },
  SET_SHOW_SUBSCRIPTION_AUTO_RENEWAL_IN_CHECKOUT(state, payload) {
    state.showSubscriptionAutoRenewalInCheckout = payload;
  },
  SET_CART_ITEMS(state, payload) {
    state.cartItems = payload;
  },
  SET_PRICE_DATA(state, payload) {
    state.cartPriceData = payload;
  },
};
